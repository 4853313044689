$('a[href*="#"]:not([href="#"])').click(function() {
  if ($(this).attr("data-anchor-offset") === undefined) {
    var offset = 0;
  } else {
  var offset = -($(this).attr("data-anchor-offset")); // <-- change the value here
  }

  if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
    var target = $(this.hash);
    target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
    if (target.length) {
        $('html, body').animate({
            scrollTop: target.offset().top + offset
        }, 300);
        return false;
    }
  var offset = 0;
  }
});

// JF stop modal video on close //
$(function(){
  $('#videoModalCenter').modal({
      show: false
  }).on('hidden.bs.modal', function(){
      $(this).find('video')[0].pause();
  });
});
