$( document ).ready(function() {

    function getQueryVariable(variable) {
        var query = window.location.search.substring(1);
        var parms = query.split('&');
        for (var i = 0; i < parms.length; i++) {
            var pos = parms[i].indexOf('=');
            if (pos > 0 && variable == parms[i].substring(0, pos)) {
                return parms[i].substring(pos + 1);;
            }
        }
        return "";
    }

    // console.log((getQueryVariable('email')).replace('%40', '@'));
    document.getElementById('email').value = (getQueryVariable('email').replace('%40', '@'));

});
