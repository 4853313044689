function toggleMobileMenu() {
  $(this).toggleClass('is-active');
  $('nav#nav-mobile').toggleClass('is-active');
  $('.hamburger--squeeze').toggleClass('is-active');
  $('header').toggleClass('is-active');
  $('body').toggleClass('no-scroll');
}

$('.hamburger').on('click', function() {
  toggleMobileMenu();
});

$('nav#nav-mobile ul.level-1 li ul.level-2 li ul.level-3 li a').on('click', function() {
  toggleMobileMenu();
});

// First level nemu toggle //
$('nav#nav-mobile ul.level-1 li').on('click', function() {
  console.log('click-first');

  if ($(event.target).closest('li').is(event.currentTarget)) {
    if ($('ul.level-2', this).parent().hasClass("expand")) {
      event.stopPropagation();
    } else {
      if ($('ul.level-2', this).hasClass('expand')) {
        $('ul.level-2', this).toggleClass('expand');
        $(this).toggleClass('is-active')
      } else {
        $(this).parent().find('.expand').removeClass('expand');
        $(this).parent().find('.is-active').removeClass('is-active');
        $('ul.level-2', this).addClass('expand');
        $(this).toggleClass('is-active')
      }
    }
  }
});

// Second level nemu toggle //
$('nav#nav-mobile ul.level-1 li ul.level-2 li').on('click', function() {
  console.log('click');
  if ($(event.target).closest('li').is(event.currentTarget)) {
    if ($('ul', this).parent().hasClass("open")) {
      event.stopPropagation();
    } else {
      if ($('ul', this).hasClass('open')) {
        $('ul', this).toggleClass('open');
      } else {
        $(this).parent().find('.open').removeClass('open');
        $('ul', this).addClass('open');
      }
    }
  }
});

// Cache selectors
var lastId,
topMenu = $("#nav-mobile"),
topMenuHeight = topMenu.outerHeight(),
// All list items
menuItems = topMenu.find("a"),
// Anchors corresponding to menu items
scrollItems = menuItems.map(function(){
  var item = $($(this).attr("href"));
  if (item.length) { return item; }
});

// Bind to scroll
$(window).scroll(function(){
  // Get container scroll position
  var fromTop = $(this).scrollTop()+topMenuHeight;

  // Get id of current scroll item
  var cur = scrollItems.map(function(){
    if ($(this).offset().top < fromTop)
      return this;
  });
  // Get the id of the current element
  cur = cur[cur.length-1];
  var id = cur && cur.length ? cur[0].id : "";

  if (lastId !== id) {
      lastId = id;
      // Set/remove active class
      menuItems
        .parent().removeClass("active")
        .end().filter("[href='#"+id+"']").parent().addClass("active")
  }

  $('ul.level-2').find('li').parent('ul').removeClass('open');
  $('ul.level-2').find('li.active').parent('ul').addClass('open');

});
