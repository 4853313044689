$( document ).ready(function() {
  backToTop();
  vh = window.innerHeight;
  headerHeight = $('header').height();
  $('.brand-page .banner, .banner').height(vh - headerHeight);
  $('.brand-page .banner, .banner').css('top', headerHeight + "px");
  $('#first-content-anchor ').css('padding-bottom', headerHeight + "px");
});

// check view height
var resizeTimeout = 0;
$(window).resize(function () {
  backToTop();

  if (resizeTimeout)
    clearTimeout(resizeTimeout);

  resizeTimeout = setTimeout(function () {

    vh = window.innerHeight;
    headerHeight = $('header').height();
    $('.brand-page .banner, .banner').height(vh - headerHeight);
    $('.brand-page .banner, .banner').css('top', headerHeight + "px");
    $('#first-content-anchor ').css('padding-bottom', headerHeight + "px");

    console.log(headerHeight);
    console.log(vh);
  }, 200);
});

$(window).scroll(function(){
  backToTop();
});

function backToTop() {
  vh = window.innerHeight;
  scrollPosition = $(window).scrollTop();

  if(scrollPosition > vh) {
    $('.arrow--back-to-top').css({ 'opacity' : 1 });
  } else {
    $('.arrow--back-to-top').css({ 'opacity' : 0 });
  }
}

// // JF Hide header on scroll
// var didScroll,
// lastScrollTop = 0,
// delta = 5,
// navbarHeight = $('.header-hide').outerHeight();

// $(window).scroll(function (event) {
//   didScroll = true;
// });

// setInterval(function () {
//   if (didScroll) {
//     hasScrolled();
//     didScroll = false;
//   }
// }, 250);

// function hasScrolled() {
// var st = $(this).scrollTop();

// // Make sure they scroll more than delta
// if (Math.abs(lastScrollTop - st) <= delta)
// return;

// // If they scrolled down and are past the navbar, add class .nav-up.
// // This is necessary so you never see what is "behind" the navbar.
// if (st + $(window).height() > $(document).height() - 100) {
// // Scroll to bottom
// $('.header-hide').removeClass('nav-up');

// } else if (st > lastScrollTop && st > navbarHeight) {
// // Scroll Down
// $('.header-hide').addClass('nav-up');

// } else if (st < 200) {
// //Scroll to top
// $('.header-hide').removeClass('nav-up');

// } else if (st + $(window).height() < $(document).height()) {
// // Scroll Up
// $('.header-hide').removeClass('nav-up');

// }

// lastScrollTop = st;
// }
// // JF-END - Hide header on scroll
